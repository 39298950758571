import React from "react";
import "./App.scss";
import Bars from "./components/Bars/Bars.js";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./containers/Home/Home.js";
import Idemo from "./containers/Idemo/Idemo";
import Kalendar from "./containers/Kalendar/Kalendar";
import Manastiri from "./containers/Manastiri/Manastiri";
import Ikone from "./containers/Ikone/Ikone";
import SinglePost from "./containers/SinglPost/SinglePost";
import SinglePost2 from "./containers/SinglPost/SinglePost2";
import ScrollToTop from "./UI/ScrollToTop/ScrollToTop";
import news from "./all__news";
import HeadHelmet from "./components/HeadHelmet/HeadHelmet";
import { LocationProvider } from "./shared/LocationContext";

export default function App() {
  return (
    <Router>
      <LocationProvider>
        <HeadHelmet allNews={news} />
        <ScrollToTop />
        <div className="app">
          <Bars />
          <div className="app-wrapper">
            <Routes>
              <Route path="/" exact element={<Home />} />
              <Route path="/ikone" exact element={<Ikone />} />
              <Route path="/crkveni-kalendar" exact element={<Kalendar />} />
              <Route
                path="/crkveni-kalendar/:id/:id"
                exact
                element={<Kalendar />}
              />
              <Route
                path="/ostalo/:id"
                exact
                element={<SinglePost2 data={news} />}
              />
              <Route path="/manastiri" exact element={<Manastiri />} />
              <Route
                path="/manastirske-price/:id"
                exact
                element={<SinglePost2 data={news} />}
              />
              <Route
                path="/slave/:id"
                exact
                element={<SinglePost2 data={news} />}
              />
              <Route path="/manastiri/:id/:id" exact element={<SinglePost />} />
              {/* <Route path="/manastiri/:id/:id" exact element={<Ikone />} /> */}
              <Route path="/idemo" exact element={<Idemo />} />
            </Routes>
          </div>
        </div>
      </LocationProvider>
    </Router>
  );
}
