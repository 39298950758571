import React from "react";
import ContentEx from "./ContentEx";
import "./ContentExBox.scss";

export default function ContenExBox() {
  return (
    <section className="home__box__3">
      <div className="home_3_title">
        <a href="https://agroweb.rs/" target="_blank">
          <h2>Agroweb.rs</h2>
        </a>
      </div>
      <div className="box3">
        <div className="box3__left">
          <ContentEx n={0} classes="test1 box3" />
          <ContentEx n={1} classes="test1 box3" />
          <ContentEx n={2} classes="test1 box3" />
        </div>
        <div className="box3__right"></div>
      </div>
    </section>
  );
}
