import React, { useState } from "react";
// import AdvModule from "../../components/AdvModule/AdvModule";
import Calendar from "../../components/Calendar/Calendar";
import ContenExBox from "../../components/ContentEx/ContenExBox";
import "./Kalendar.scss";
import { useGlobalLocation } from "../../shared/LocationContext";
import AdManagerSlot from "../../components/AdvModule/AdManagerSlot";

export default function Kalendar() {
  const location = useGlobalLocation();
  // let currentYear = new Date().getFullYear();
  // console.log("Year", currentYear);
  const [calendarYear, setCalendarYear] = useState(2022);
  return (
    <div className="kalendar">
      <div className="banner-wrapper">
        <AdManagerSlot
          adUnitPath={location.pathname}
          slotNumber={"div-gpt-ad-1723658374440-0"}
        />
      </div>
      <div className="kalendar-wrapper">
        <div className="kalendar-left">
          <Calendar year={calendarYear} />
          {/* <div className="zadusnice">Zadusnice</div> */}
        </div>
        <div className="kalendar-right">
          {/* <div className="posni_kalendar">
            <h2>Kalendar posta</h2>
            <div
              style={{
                width: "100%",
                height: "300px",
                border: "1px solid red",
              }}
            >
              Kalendar posta
            </div>
          </div> */}
          <div className="banner-wrapper xl_sticky">
            <AdManagerSlot
              adUnitPath={location.pathname}
              slotNumber={"div-gpt-ad-1723682121612-0"}
            />
          </div>
          {/* <AdvModule classes={"adClass xl_sticky"} size={"xl"} /> */}
        </div>
      </div>
      <div className="banner-wrapper">
        <AdManagerSlot
          adUnitPath={location.pathname}
          slotNumber={"div-gpt-ad-1724367417806-0"}
        />
      </div>

      {/* <AdvModule classes={"adClass__XXL"} size={"xxl"} /> */}
      <ContenExBox />
    </div>
  );
}
