import React, { useEffect, useRef } from "react";
import { useGlobalLocation } from "../../shared/LocationContext";

const AdManagerSlot = ({ slotNumber }) => {
  console.log("Slot number", slotNumber);
  const { location } = useGlobalLocation();
  console.log("Location", location.pathname, window.googletag);
  const previouslocation = useRef("");
  // console.log("previouslocation", previouslocation);

  //refresh adSlot once when page is loaded
  useEffect(() => {
    console.log("AdSlot useEffect");
    if (typeof window !== "undefined" && window.googletag) {
      console.log("AdSlot useEffect PUSH");
      window.googletag.cmd.push(function () {
        window.googletag.display(slotNumber);
      });
    }
  }, []);

  // Refresh ad slot when the article changes
  useEffect(() => {
    if (
      previouslocation.current &&
      previouslocation.current !== location.pathname
    ) {
      console.log("Refreshed Ad Slot");
      if (typeof window !== "undefined" && window.googletag) {
        window.googletag.cmd.push(function () {
          window.googletag.pubads().refresh();
        });
      }
    }
    previouslocation.current = location.pathname;
  }, [location.pathname]);

  return <div id={slotNumber}></div>;
};

export default AdManagerSlot;
