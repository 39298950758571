import React from "react";

export default function Ikone() {
  return (
    <div>
      Ikonopis (ili ikonopisanje) je posebna slikarska tehnika koju neguje
      hrišćanska crkva (naročito Pravoslavna).U Pravoslavnoj Crkvi naročito je
      raširen i razvijen kult ikona, i mada ikone spadaju u kult i drugih
      konfesija, pravoslavlje je najfinije i najdetaljnije razvilo ikonopis tako
      da sva pravila i načini ikonopisa, dole napisana, karakteristična su za
      Pravoslavnu Crkvu. U drugim konfesijama koje poštuju ikonu ova pravila ne
      moraju biti poštovana (primer je Rimokatolička crkva u kojoj nije detaljno
      preciziran i dogmatski određen način slikanja ikona). U Pravoslavnoj Crkvi
      postoje određeni kanoni (pravila) ikonopisanja kojih se pridržava svaki
      ikonopisac. Pravila nisu zvanično propisana već se prenose generacijama sa
      majstora na učenika. Osnovno nepisano pravilo je da se ikona radi po
      obrascu (predložku) neke starije ikone (koja je opšte prihvećeni obrazac).
      Slikanje ikona naravno ne mora uvek biti po predlošku,već po opisu iz
      npr."Ohridskog prologa"ili i samog SVETOG PISMA,ali to naravno zavisi od
      veštine, znanja i obrazovanja samog umetnika.
    </div>
  );
}
